.is--business {
  .rwd002-container {
    margin-top: 100px;
    .rwd002-detail {
      padding: 0;
      margin-block: 0;
      &__entry {
        max-width: unset;
      }
    }
  }

  .business_ttl {
    text-align: center;
    strong,
    small {
      display: block;
      line-height: 1;
    }
    strong {
      font-size: 31px;
      color: $thema-bl;
      letter-spacing: 0.14em;
    }
    small {
      color: #2abbbc;
      font-size: 10px;
      margin-top: 10px;
    }
  }

  .block-lead {
    .container {
      max-width: 1170px;
      display: grid;
      grid-template-columns: 540px 1fr;
    }
    h3 {
      clip-path: inset(0 100% 0 0);
      width: 100%;
      span {
        display: inline-block;
        color: #fff;
        background-color: $thema-bl;
        font-size: 30px;
        font-weight: 700;
        line-height: 1;
        padding: 11px 18px;
        letter-spacing: 0.1em;
        + span {
          margin-top: 10px;
        }
      }
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 2.2;
      letter-spacing: 0.14em;
      transform: translateY(30px);
      opacity: 0;
      @include pc {
        margin-top: -10px;
      }
    }
    &.loaded {
      h3 {
        clip-path: inset(0 0 0 0);
        transition: 0.5s ease-out 0.2s;
      }

      p {
        transform: translateY(0);
        opacity: 1;
        transition: 0.5s 0.5s;
      }
    }
  }

  .lead_dl {
    text-align: center;
    dt {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.7;
    }
    dd {
      font-size: 15px;
      line-height: 1.9;
      margin-top: 20px;
      letter-spacing: 0.12em;
    }
  }

  .border_wrap {
    padding: 44px 30px 47px;
    box-sizing: border-box;
    border: 1px solid #d6d6d6;
    ul {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
      max-width: 600px;
      margin: auto;
      @include pc {
        position: relative;
        left: 46px;
      }
      li {
        font-size: 14px;
        line-height: 1;
        letter-spacing: 0.14em;
        &::before {
          content: "";
          display: inline-block;
          position: relative;
          margin-right: 10px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #8d99b1;
          bottom: 3px;
        }
      }
    }
  }

  .fixed_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    clip-path: inset(0);
    &::before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
  }

  .block-car {
    margin-top: 120px;
    padding-block: 130px 140px;
    position: relative;
    overflow: hidden;
    z-index: -1;
    .fixed_bg::before {
      background-image: url(/assets/img/business/car_bg@2x.jpg);
    }
    .business_ttl strong,
    dt,
    dd,
    h4,
    li {
      color: #fff;
    }

    dl {
      margin-top: 55px;
    }

    .img_wrap {
      margin: 30px auto 0;
      max-width: 870px;
      @include pc {
        picture {
          position: relative;
          left: 30px;
        }
      }
    }

    .border_wrap {
      margin-top: -40px;
      border-color: #fff;
      padding-top: 37px;
      h4 {
        margin-bottom: 20px;
        font-size: 19px;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.14em;
        text-align: center;
      }
    }
  }

  .block-other {
    margin-block: 100px 107px;
    dl {
      margin-top: 40px;
    }
    .border_wrap {
      margin-top: 56px;
    }
  }

  .block-flow {
    padding-top: 93px;
    background-color: #f4f6f9;
    .lead {
      margin-top: 25px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.9;
      text-align: center;
    }

    ul {
      margin-top: 55px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
      li {
        min-height: 300px;
        background-color: #fff;
        border-radius: 3px;
        padding: 35px 20px 30px;
        box-sizing: border-box;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 10px;
          height: 29px;
          background: url(/assets/img/business/icon_ttl.svg) no-repeat 0 / contain;
          left: 0;
          right: 0;
          top: -6px;
          margin: auto;
        }
        h4 {
          font-size: 21px;
          font-weight: 700;
          color: $thema-bl;
          text-align: center;
          line-height: 1;
        }
        figure {
          display: grid;
          place-content: center;
          width: 85px;
          height: 64px;
          margin: 25px auto 0;
        }
        p {
          margin-top: 32px;
          font-size: 13px;
          line-height: 2;
          letter-spacing: 0.14em;
        }
        &:nth-of-type(1) img {
          width: 74px;
        }
        &:nth-of-type(2) img {
          width: 63px;
        }
        &:nth-of-type(3) img {
          width: 82px;
        }
        &:nth-of-type(4) img {
          width: 85px;
        }
      }
    }

    .img_wrap {
      margin-top: 140px;
      height: 520px;
      position: relative;
      .fixed_bg::before {
        background-image: url(/assets/img/business/flow_bg@2x.jpg);
      }
    }
  }

  .bn_wrap {
    margin-top: 70px;
    @include pc {
      .bn_link .text_wrap {
        grid-template-columns: 220px 1fr;
      }
    }
  }

  @include sp {
    .rwd002-container {
      margin-top: 30px;
    }

    .business_ttl {
      strong {
        font-size: 22px;
        line-height: 1.2;
      }
      small {
        margin-top: 4px;
      }
    }

    .block-lead {
      .container {
        display: block;
      }
      h3 {
        @media (min-width: 585px){
          width: 65%;
        }
        span {
          font-size: 18px;
          padding: 7px 13px;
          + span {
            margin-top: 6px;
          }
          @media (min-width: 585px) {
            padding: 11px 18px;
          }
        }
      }
      p {
        line-height: 1.8;
        margin-top: 20px;
      }
    }

    .lead_dl {
      dt {
        font-size: 16px;
        line-height: 1.5;
      }
      dd {
        font-size: 15px;
        line-height: 1.6;
        margin-top: 20px;
        text-align: left;
        letter-spacing: 0.14em;
      }
    }

    .border_wrap {
      padding: 23px 25px 26px;
      ul {
        display: block;
        max-inline-size: max-content;
        li {
          font-size: 14px;
          + li {
            margin-top: 10px;
          }
          &::before {
            margin-right: 8px;
          }
        }
      }
    }

    .block-car {
      margin-top: 50px;
      padding-block: 54px 66px;

      dl {
        margin-top: 20px;
      }

      .img_wrap {
        margin-top: 5px;
        .unit {
          display: flex;
          flex-direction: column;
          align-items: center;
          ol {
            margin: -47px auto 0;
            max-inline-size: max-content;
            li {
              font-size: 14px;
              font-weight: 700;
              line-height: 1.2;
              letter-spacing: 0.13em;
              + li {
                margin-top: 8px;
              }
            }
          }
          + .unit {
            margin-top: 0;
            ol {
              margin-top: -63px;
            }
          }
        }
      }

      .border_wrap {
        margin-top: 38px;
        padding-top: 20px;
        h4 {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 16px;
          letter-spacing: 0.1em;
        }
      }
    }

    .block-other {
      margin-block: 60px 58px;
      dl {
        margin-top: 24px;
      }
      .border_wrap {
        margin-top: 25px;
      }
    }

    .block-flow {
      padding-top: 62px;
      .lead {
        margin-top: 22px;
        line-height: 1.7;
        text-align: left;
        @media(min-width:539px){
          text-align: center;
        }
      }

      ul {
        margin-top: 32px;
        display: block;
        li {
          min-height: 270px;
          padding: 33px 30px 30px;
          text-align: left;
            @media(min-width:723px){
              text-align: center;
            }
          + li {
            margin-top: 20px;
          }
          &::before {
            width: 10px;
            height: 29px;
            top: -6px;
          }
          figure {
            margin-top: 20px;
          }
          p {
            margin-top: 24px;
          }
          
        }
      }

      .img_wrap {
        margin-top: 70px;
        height: auto;
        background: none;
        img{
          width: 100%;
        }
      }
    }

    .bn_wrap {
      margin-top: 44px;
    }
    .bn_link{
      img{
        width: 100%;
      }
    }
  }
}
