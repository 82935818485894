$gnav-height-md: 120px;
$gnav-height-xs: 55px;

#allbox {
  // ナビバーが画面固定されたときのクリアランス
  padding-top: 90px;
  @include sp {
    padding-top: 60px;
  }
}

.rwd002-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-left: map-get($spacers, 3);
  padding-right: 0;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 99;

  @include media-up(md) {
    padding-left: map-get($spacers, 4);
  }

  @include media-up(lg) {
    padding-left: map-get($spacers, 5); // ie用
    padding-right: map-get($spacers, 5); // ie用
    padding-left: min(map-get($spacers, 5), 5vw);
    padding-right: 26px;
  }

  @include media-down(lg) {
    height: 60px;
    padding-inline: 0 10px;
    overflow: hidden;
  }

  &__logo {
    flex-grow: 1;
    margin: 0;
  }

  &__logo-link {
    display: block;
    width: 160px;
    img {
      width: 100%;
    }
    @include sp {
      width: 150px;
      margin: auto;
    }
  }
}

.rwd002-header__drawerBtn {
  @extend .btn;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 101;
  
  @include media-down(lg) {
    width: 100px;
    background-color: fade-out($white, 0.5);
  }

  @include media-down(md) {
    width: 55px;
  }

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: $body-color;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}
