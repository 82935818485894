.is--strength {
  .rwd002-container {
    margin-top: 92px;
    overflow: hidden;
    .rwd002-detail {
      padding: 0;
      margin-block: 0;
      &__entry {
        max-width: unset;
      }
      @include pc {
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 72%;
          height: calc(100% + 320px);
          background-color: #F4F6F9;
          left: 0;
          top: -130px;
          z-index: -1;
        }
      }
    }
  }

  .lead {
    font-size: 17px;
    font-weight: 700;
    line-height: 2.1;
    text-align: center;
    letter-spacing: .14em;
  }

  .point_text {
    h3 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        line-height: 1;
      }
      .point {
        @include Jost(600);
        font-size: 20px;
        color: $thema-rd;
        position: relative;
        margin-left: 5px;
        letter-spacing: .14em;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 32px;
          height: 20px;
          background: url(/assets/img/common/icon_ttl_ring.svg) no-repeat 0 / contain;
          left: -20px;
          top: -14px;
        }
      }
      .bl_ttl {
        margin-top: 30px;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: .14em;
        position: relative;
        padding: 7px 14px 7px 17px;
        margin-left: 6px;
        color: #fff;
        background-color: $thema-bl;
        z-index: 1;
        &::before, &::after {
          content: '';
          display: block;
          position: absolute;
          width: 12px;
          height: 100%;
          background-color: $thema-bl;
          clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%, 0 50%);
          top: 0;
          z-index: -1;
        }
        &::before {
          left: -6px;
        }
        &::after {
          right: -6px;
          transform: scaleX(-1);
        }
      }
    }
    dl {
      margin-top: 28px;
      dt {
        font-size: 30px;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: .14em;
        color: $thema-bl;
        @include max(1000px){
          font-size: 26px;
        }
      }
      dd {
        margin-top: 33px;
        font-size: 15px;
        line-height: 1.9;
        letter-spacing: .12em;
      }
    }
  }

  @include pc {
    .flex_wrap {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      figure {
        width: 42%;
      }
      .point_text {
        width: 49%;
      }
      &.rev {
        flex-direction: row-reverse;
      }
    }
  }

  .block-p2, .block-p3 {
    .container {
      max-width: 1250px;
    }
  }

  figure {
    &.fig {
      aspect-ratio: 480 / 493;
    }
    &.fig1a {
      aspect-ratio: 499 / 498;
      position: relative;
      figcaption {
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 14px;
      }
    }
    &.fig1b {
      aspect-ratio: 461 / 250;
    }
  }

  .block-p1 {
    padding-block: 87px;
    .container {
      max-width: 1200px;
    }
    @include pc {
      .flex_wrap {
        @include max(1200px){
          .point_text dl dt {
            font-size: 26px;
          }
        }
        figure {
          margin-top: 20px;
          @include min(1250px){
            transform: scale(1.08);
            transform-origin: left top;
          }
        }
      }
    }
    .secont_wrap {
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      figure {
        width: 42%;
        figcaption {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          margin-top: 5px;
          column-gap: 2%;
          span {
            font-size: 14px;
            line-height: 1;
            @include sp {
              font-size: 13px;
            }
          }
        }
      }
      p {
        width: 51%;
        font-size: 15px;
        line-height: 1.9;
        letter-spacing: .12em;
      }
    }
    .border_text {
      display: grid;
      place-content: center;
      width: 450px;
      font-size: 15px;
      letter-spacing: .14em;
      border: 1px solid $font-color;
      border-radius: 3px;
      margin: 67px auto 0;
      padding-block: 10px;
      @include pc {
        line-height: 1.5;
      }
    }
  }

  .block-p2 {
    background-color: #2A2A2A;
    padding-block: 105px 125px;
    
    @include pc {
      figure {
        margin-top: 60px;
      }
    }
    .flex_wrap {
      position: relative;
      z-index: 1;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 333px;
        height: 333px;
        background: url(/assets/img/strength/point_02_object.svg) no-repeat 0 / contain;
        right: -97px;
        top: -225px;
        z-index: -1;
      }
    }
    .point_text {
      h3 .bl_ttl {
        background-color: #fff;
        color: $thema-bl;
        &::before, &::after {
          background-color: #fff;
        }
      }
      dl {
        dt, dd {
          color: #fff;
        }
        dd span {
          display: block;
          margin-top: 15px;
          color: #fff;
        }
      }
    }
  }

  .block-p3 {
    padding-block: 135px 145px;
    @include pc {
      .flex_wrap figure {
        margin-top: 40px;
      }
    }
  }

  .img_wrap {
    margin-top: 45px;
    img{
      width: 100%;
    }
  }

  .block-p4, .block-p5 {
    .container {
      max-width: 800px;
    }
  }

  .block-p4 {
    padding-block: 114px 150px;
    background: url(/assets/img/strength/point_04_bg@2x.jpg) no-repeat center / cover;
    .bn_link::after {
      background-image: url(/assets/img/common/arrow_wh.svg);
    }
    @include pc {
      .point_text dl dt {
        letter-spacing: .05em;
      }
    }
  }

  .block-p5 {
    padding-top: 138px;
  }
  

  @include sp {
    .rwd002-container {
      margin-top: 34px;
    }

    .lead {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: .17em;
    }
  
    .point_text {
      h3 {
        .point {
          font-size: 18px;
          margin-left: 5px;
          &::before {
            width: 29px;
            height: 18px;
            left: -19px;
            top: -12px;
          }
        }
        .bl_ttl {
          margin: 25px 6px 0;
          font-size: 14px;
          letter-spacing: .08em;
          padding-inline: 9px 12px;
          width: calc(100% - 12px);
          text-align: center;
          &::before, &::after {
            width: 12px;
          }
          &::before {
            left: -6px;
          }
          &::after {
            right: -6px;
          }
        }
      }
      dl {
        margin-top: 20px;
        dt {
          font-size: 18px;
          line-height: 1.7;
          letter-spacing: .1em;
          text-align: center;
        }
        dd {
          margin-top: 18px;
          line-height: 1.7;
          letter-spacing: .14em;
        }
      }
    }
  
    .block-p1 {
      padding-block: 35px 56px;

      .flex_wrap {
        margin-top: 10px;
        .point_text {
          margin-top: 10px;
        }
      }
      .fig1a {
        margin-bottom: 22px;
        figcaption {
          font-size: 13px;
        }
      }
      .secont_wrap {
        margin-top: 26px;
        display: block;
        figure {
          width: 100%;
        }
        p {
          width: 100%;
          line-height: 1.7;
          letter-spacing: .14em;
          margin-top: 22px;
        }
      }
      .border_text {
        width: 100%;
        font-size: 14px;
        margin-top: 20px;
        line-height: 1.4;
        padding-block: 10px;
        text-align: center;
      }
    }
  
    .block-p2 {
      padding-block: 67px 46px;
      .flex_wrap {
        &::before {
          width: 212px;
          height: 212px;
          right: -64px;
          top: -104px;
        }
        .point_text {
          margin-top: 36px;
        }
      }
    }
  
    .block-p3 {
      padding-block: 57px 46px;
      .point_text {
        margin-top: 36px;
      }
    }
  
    .block-p4 {
      padding-block: 65px;
      background-image: url(/assets/img/strength/point_04_bg_sp@2x.jpg);
      background-position: center bottom;
      .bn_link::after {
        background-image: url(/assets/img/common/arrow_wh.svg);
      }
    }
  
    .block-p5 {
      padding-top: 80px;
    }
  }
}