footer {
  position: relative;
  .container {
    @include pc {
      max-width: calc(1080px + 4rem);
    }
  }

  .contact_wrap {
    background-color: #fff;
    border-top: 1px solid #E1E7F5;
    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      height: 290px;
      max-width: calc(980px + 4rem);
    }
    .tel_wrap {
      padding-left: 30px;
      position: relative;
      dt {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: .2em;
        color: $thema-bl;
        line-height: 1;
      }
      .num {
        line-height: 1;
        margin-top: 12px;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 22px;
          height: 22px;
          background: url(/assets/img/common/icon_tel.svg) no-repeat 0 / contain;
          left: -29px;
          top: -1px;
        }
        @include pc {
          line-height: 19px;
        }
        a {
          font-size: 32px;
          @include Mulish(600);
          color: $thema-bl;
        }
      }
      .note {
        font-size: 11px;
        font-weight: 700;
        color: $thema-bl;
        line-height: 1;
        margin-top: 7px;
      }
    }
    .btn_wrap {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: 320px;
        height: 65px;
        border-radius: 5px;
        border: 1px solid $thema-bl;
        padding-right: 10px;
        svg {
          stroke: #3a4871;
          fill: none;
          stroke-miterlimit: 10;
          transition: .3s;
          width: 26px;
        }
        span {
          box-sizing: border-box;
          font-weight: 700;
          color: $thema-bl;
          transition: .3s;
        }
        &:hover {
          background-color: $thema-rd;
          border-color: $thema-rd;
          svg {
            stroke: #fff;
          }
          span {
            color: #fff;
          }
        }
      }
    }
    @include max(1100px){

    }
  }

  .main_wrap {
    padding-block: 160px 22px;
    background: url(/assets/img/common/foot_bg@2x.jpg) no-repeat center / cover;

    .top_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1000px;
      column-gap: 30px;
      .left_wrap {
        .logo {
          width: 213px;
          a:hover {
            opacity: .7;
          }
        }
        .text_wrap {
          margin-top: 37px;
          dl {
            dt {
              font-size: 16px;
              font-weight: 700;
              color: #fff;
              line-height: 1;
              margin-bottom: 7px;
            }
            dd {
              font-size: 11px;
              font-weight: 500;
              color: #fff;
              line-height: 1;
              letter-spacing: .21em;
              a {
                color: #fff;
                letter-spacing: .21em;
              }
              + dd {
                margin-top: 8px;
              }
            }
          }
          .map {
            margin-top: 35px;
            a {
              display: flex;
              align-items: center;
              width: 110px;
              border-bottom: 1px solid #fff;
              padding: 0 0 5px 2px;
              box-sizing: border-box;
              .fa-location-dot {
                line-height: 0;
                &::before {
                  content: "\f3c5";
                  color: #fff;
                  font-size: 11px;
                  transition: .3s;
                }
              }
              span {
                font-size: 12px;
                color: #fff;
                margin-left: 7px;
                letter-spacing: .15em;
                transition: .3s;
              }
              &:hover {
                border-color: $thema-rd;
                .fa-location-dot::before {
                  color: $thema-rd;
                }
                span {
                  color: $thema-rd;
                }
              }
            }
          }
        }
      }

      nav {
        ul {
          display: flex;
          flex-wrap: wrap;
          row-gap: 7px;
          li {
            line-height: 1;
            color: #fff;
            a {
              color: #fff;
              background: linear-gradient($thema-rd, $thema-rd) no-repeat left bottom / 100% 0;
              &:visited {
                color: #fff;
              }
              &:hover {
                background-size: 100% 1px;
                color: $thema-rd;
              }
            }
          }
        }
        .main_list {
          li {
            position: relative;
            line-height: 1;
            &:not(:last-of-type)::after {
              content: '|';
              margin-inline: 10px;
              opacity: .5;
            }
            a {
              font-size: 13px;
              font-weight: 700;
            }
          }
        }
        .other_list {
          margin-top: 12px;
          li {
            line-height: 1;
            font-size: 12px;
            @include Mulish(600);
            letter-spacing: .14em;
            + li::before {
              content: '/';
              margin-inline: 8px;
            }
          }
        }
      }
    }

    .bottom_wrap {
      margin-top: 140px;
      .copy {
        font-size: 10px;
        @include Mulish(600);
        color: rgba(255, 255, 255, .5);
        line-height: 1;
        text-align: center;
        letter-spacing: .19em;
      }
      .login {
        width: 13px;
        position: absolute;
        right: 20px;
        bottom: 26px;
        a:hover {
          opacity: .7;
        }
      }
    }
  }
}

@include sp {
  footer {
    margin-top: 100px;
    .contact_wrap {
      padding-block: 65px 46px;
      position: relative;
      .sec_ttl {
        text-align: center;
      }
      .container {
        display: block;
        height: auto;
      }
      .block_ttl {
        text-align: center;
        strong {
          font-size: 33px;
          position: unset;
          &::before {
            background-image: url(/assets/img/common/icon_ttl_foot_sp.svg);
            width: 8px;
            height: 45px;
            left: 0;
            right: 0;
            bottom: auto;
          }
        }
        small {
          font-size: 13px;
          margin-top: 8px;
        }
      }
      .tel_wrap {
        padding-left: 0;
        margin: 28px;
        text-align: center;
        dt {
          font-size: 13px;
          letter-spacing: .14em;
        }
        .num {
          max-inline-size: max-content;
          margin: 10px auto 0;
          &::before {
            width: 22px;
            height: 22px;
            top: 6px;
          }
          a {
            font-size: 32px;
          }
        }
        .note {
          margin-top: 6px;
          margin-right: 10px;
        }
      }
      .btn_wrap {
        margin-top: 22px;
        a {
          column-gap: 10px;
          padding-right: 10px;
          margin: auto;
          svg {
            width: 26px;
          }
        }
      }
    }
  
    .main_wrap {
      padding-block: 82px 22px;
  
      .top_wrap {
        display: block;
        .left_wrap {
          .logo {
            width: 213px;
            margin: auto;
          }
          .text_wrap {
            margin-top: 37px;
            dl {
              text-align: center;
              dt {
                font-size: 16px;
                margin-bottom: 7px;
                letter-spacing: .14em;
              }
              dd {
                font-size: 11px;
                letter-spacing: .14em;
                margin-top: 15px;
                a {
                  letter-spacing: .14em;
                }
                + dd {
                  margin-top: 10px;
                }
              }
            }
            .map {
              margin-top: 26px;
              a {
                width: 110px;
                padding: 0 0 5px 2px;
                margin: auto;
                .fa-location-dot {
                  &::before {
                    font-size: 11px;
                  }
                }
                span {
                  font-size: 12px;
                  margin-left: 7px;
                  letter-spacing: .15em;
                }
              }
            }
          }
        }
  
        nav {
          margin-top: 50px;
          .main_list {
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 15px;
            max-width: 315px;
            margin: auto;
            li {
              &:nth-of-type(4)::after {
                display: none;
              }
              a {
                font-size: 13px;
              }
            }
          }
          .other_list {
            margin-top: 20px;
            justify-content: center;
            li {
              font-size: 10px;
              + li::before {
                margin-inline: 5px;
              }
            }
          }
        }
      }
  
      .bottom_wrap {
        margin-top: 80px;
        .copy {
          font-size: 10px;
          margin-inline: -25px;
        }
        .login {
          width: 13px;
          right: 20px;
          bottom: 45px;
        }
      }
    }
  }  
}

.is--news, .is--contact, .is--sitemap, .is--privacy {
  footer {
    margin-top: 120px;
    @include sp {
      margin-top: 60px;
    }
  }
}