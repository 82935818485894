.rwd002-breadcrumbs {
  display: flex;
  margin-top: map-get($spacers, 6);
  margin-bottom: map-get($spacers, 6);
  padding-inline: 25px;
  
  @include media-up(lg) {
    padding-inline: 50px
  }
  &__list {
    @extend .breadcrumb;
    width: 100%;
    max-width: 1100px;
    margin: auto;
    row-gap: 6px;
  }
  &__item {
    color: #1b2681;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    & + &{
      padding-left: 0;
    }
    & + &::before {
      background-color: #b1bacb;
      bottom: 3px;
      content: "";
      display: inline-block;
      height: 1px;
      margin-inline: 12px;
      position: relative;
      width: 10px;
    }
    a {
      span {
        color: #c3c4c4;
      }
      &:hover {
        opacity: .7;
      }
    }
    &:last-child {
      span {
        color: #1b2681;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
      }
    }
  }
}
