.is--company {
  &.is--article-1 {
    .rwd002-container {
      margin-top: 45px;
      .rwd002-detail {
        padding: 0;
        margin-block: 0;
        &__entry {
          max-width: unset;
        }
      }
      a, a:hover, a:visited {
        color: $font-color;
      }
    }
  
    .block-link {
      .anchor_list {
        display: grid;
        grid-template-columns: repeat(3, 160px);
        column-gap: 10px;
        justify-content: center;
        li {
          a {
            display: grid;
            place-content: center;
            position: relative;
            height: 30px;
            border-radius: 15px;
            border: 2px solid #ECEEEE;
            box-sizing: border-box;
            font-size: 12px;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 6px;
              height: 4px;
              background: url(/assets/img/company/arrow_bottom.svg) no-repeat 0 / contain;
              right: 12px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            &:hover {
              opacity: .7;
            }
          }
        }
      }
    }
  
    @include pc {
      .grid_container {
        display: grid;
        grid-template-columns: 270px 1fr;
      }
    }
  
    table {
      width: 100%;
      tr {
        display: grid;
        box-sizing: border-box;
      }
      
      &.base_table {
        tr {
          grid-template-columns: 155px 1fr;
          padding: 20px 10px;
          + tr {
            border-top: 1px solid #ECEEF3;
          }
          th, td {
            font-size: 14px;
            line-height: 2;
            font-weight: 500;
          }
          th {
            color: #868787;
          }
        }
      }
      
      &.history_table {
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: calc(100% - 66px);
          background-color: $thema-bl;
          left: 0;
          top: 33px;
        }
        tr {
          grid-template-columns: 95px 1fr;
          + tr td {
            border-top: 1px solid #ECEEF3;
          }
          th {
            padding-left: 16px;
            color: $thema-bl;
            font-size: 20px;
            @include Canta(400);
            line-height: 1;
            margin-top: 23px;
            position: relative;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: $thema-bl;
              left: -2px;
              top: 9px;
            }
          }
          td {
            padding: 20px 10px;
            dl {
              display: grid;
              grid-template-columns: 47px 1fr;
              dt {
                font-size: 14px;
                font-weight: 700;
                line-height: 2;
              }
              dd {
                font-size: 15px;
                line-height: 1.9;
              }
            }
          }
        }
      }
    }
  
    .block-info {
      padding-top: 110px;
      .ex {
        font-size: 12px;
        line-height: 1;
        text-align: right;
      }
      @include pc {
        .right_wrap {
          margin-top: -27px;
        }
      }
    }
 
    .block-history {
      margin-top: 90px;
      padding-top: 105px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 90px);
        height: 1px;
        background-color: #E9E9E9;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
      }
      @include pc {
        .right_wrap {
          margin-top: 10px;
        }
      }
    }
  
    @keyframes imgCarousel {
      100% {
        transform: translateX(-3586px);
        @include sp {
          transform: translateX(-2260px);
        }
      }
    }
  
    .carousel_wrap {
      margin-top: 125px;
      overflow: hidden;
      ul {
        display: grid;
        animation: imgCarousel 70s linear infinite;
        grid-template-columns: repeat(2, 3576px);
        min-height: 543px;
        column-gap: 10px;
        li {
          picture {
            display: block;
          }
          img {
            width: 100%;
          }
        }
      }
    }

      
    .block-access {
      padding-top: 135px;
      .map_wrap {
        .frame_wrap {
          height: 330px;
          overflow: hidden;
          iframe {
            width: 100%;
            height: calc(100% + 300px);
            margin-top: -150px;
          }
        }
        .map {
          margin-top: 20px;
          a {
            display: flex;
            align-items: center;
            width: 110px;
            border-bottom: 1px solid $thema-bl;
            padding: 0 0 4px 2px;
            box-sizing: border-box;
            margin-inline: auto 0;
            &::before {
              content: "\f3c5";
              font-family: Font Awesome\ 6 Free;
              font-weight: 900;
              font-size: 13px;
              color: $thema-bl;
              transition: .3s;
            }
            span {
              font-size: 12px;
              @include Mulish(600);
              color: $thema-bl;
              margin-left: 7px;
              letter-spacing: .13em;
              transition: .3s;
            }
            &:hover {
              border-color: $thema-rd;
              &::before {
                color: $thema-rd;
              }
              span {
                color: $thema-rd;
              }
            }
          }
        }
      }
      table {
        margin-top: 40px;
      }
    }
  
    @include sp {
      .rwd002-container {
        margin-top: 23px;
      }
    
      .block-link {
        margin-inline: 15px;
        .anchor_list {
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;
        }
      }
    
      table {
        tr {
          display: block;
          th, td {
            display: block;
            width: 100%;
          }
        }
        
        &.base_table {
          tr {
            padding: 15px 0;
            th {
              line-height: 1;
              color: #868787;
            }
            td {
              margin-top: 4px;
              line-height: 1.7;
            }
          }
        }
        
        &.history_table {
          &::before {
            display: none;
          }
          tr {
            padding: 20px 0 19px;
            margin-left: 18px;
            box-sizing: border-box;
            position: relative;
            + tr {
              border-top: 1px solid #ECEEF3;
              td {
                border-top: none;
              }
            }
            &:not(:last-of-type)::before {
              content: '';
              display: block;
              position: absolute;
              width: 1px;
              height: 100%;
              background-color: $thema-bl;
              left: -18px;
              top: 31px;
            }
            th {
              padding-left: 0;
              font-size: 20px;
              margin-top: 0;
              &::before {
                left: -20px;
              }
            }
            td {
              padding: 0;
              margin-top: 10px;
              dl {
                display: grid;
                grid-template-columns: 40px 1fr;
                dt {
                  font-size: 12px;
                  line-height: 1;
                  margin-top: 5px;
                }
                dd {
                  font-size: 14px;
                  line-height: 1.6;
                }
              }
            }
          }
        }
      }
    
      .block-info {
        padding-top: 70px;
      }
    
      .block-history {
        margin-top: 70px;
        padding-top: 70px;
        &::before {
          width: 100%;
        }
      }
    
      @keyframes imgCarousel {
        100% {
          transform: translateX(-1850px);
        }
      }
    
      .carousel_wrap {
        margin-top: 40px;
        ul {
          display: grid;
          animation: imgCarousel 70s linear infinite;
          grid-template-columns: repeat(2, 1844px);
          column-gap: 6px;
          min-height: 280px;
          li {
            picture {
              display: block;
            }
            img {
              width: 100%;
            }
          }
        }
      }

      .block-access {
        padding-top: 70px;
        .map_wrap {
          margin-top: 18px;
          .frame_wrap {
            height: 240px;
            iframe {
              height: calc(100% + 140px);
              margin-top: -70px;
            }
          }
          .map {
            margin-top: 20px;
            a {
              padding-bottom: 3px;
            }
          }
        }
        table {
          margin-top: 5px;
        }
      }
    }
  }
}