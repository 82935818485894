.is--home {
  .rwd002-container {
    margin-top: 0;
  }
  #allbox {
    padding-top: 0;
  }

  header {
    transition: 0.3s;
    &.nobg {
      background-color: transparent;
      z-index: 10;
      .rwd002-header__logo-link {
        position: relative;
        img {
          opacity: 0;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(/assets/img/common/logo_wh.svg) no-repeat 0 / contain;
          left: 0;
          top: 0;
        }
      }
      @include pc {
        .rwd002-gnav-item__link span {
          color: #fff;
          &::after {
            background-color: #fff;
          }
        }
        .rwd002-gnav-item__link.contact {
          background-color: #fff;
          span {
            color: $thema-bl;
          }
          &:hover {
            background-color: $thema-rd;
            span {
              color: #fff;
            }
            &::after {
              background-color: #fff;
            }
          }
        }
      }
      .rwd002-header__drawerBtn {
        background-color: transparent;
        &__bars {
          stroke: #fff;
        }
        &.is-active {
          .rwd002-header__drawerBtn__bars {
            stroke: $font-color;
          }
        }
      }
    }
  }

  footer {
    .main_wrap {
      background: rgba(0, 0, 0, 0.85);
    }
    @include pc {
      z-index: 1;
      &::before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: -70px;
        width: 100%;
        height: 72px;
        background-color: #fff;
        z-index: -1;
      }
    }
    @include sp {
      margin-top: 0;
    }
  }

  @keyframes mvScroll {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }
  @keyframes loadingFilter {
    0% {
      transform: scaleX(0);
    }
    50% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(1);
      opacity: 0;
    }
  }

  .mainvisual {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #eee;
    z-index: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #fff;
      left: 0;
      top: 0;
      transform-origin: left;
      pointer-events: none;
      z-index: 1;
    }
    .movie_wrap {
      position: fixed;
      inset: 0;
      video {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }

    .catch {
      position: absolute;
      left: 10.7%;
      bottom: 30%;
      width: 414px;
      clip-path: inset(0 100% 0 0);
    }

    .news_wrap {
      width: 420px;
      height: 74px;
      position: absolute;
      right: 0;
      bottom: 0;
      opacity: 0;
      a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 12px;
        width: 100%;
        height: 100%;
        border-top-left-radius: 5px;
        padding-inline: 35px 60px;
        box-sizing: border-box;
        background-color: rgba(55, 74, 114, 0.75);
        border-radius: 2px;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 12px;
          height: 11px;
          background: url(/assets/img/common/arrow_wh.svg) no-repeat 0 / contain;
          right: 28px;
          top: 0;
          bottom: 0;
          margin: auto;
          transition: 0.3s;
        }
        p,
        span {
          color: #fff;
          line-height: 1;
        }
        .info {
          display: flex;
          align-items: center;
          .date {
            font-size: 12px;
            @include Canta(400);
          }
          .cate {
            font-size: 12px;
            font-weight: 500;
            margin-left: 14px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              height: 13px;
              background-color: #fff;
              left: -7px;
              top: 0px;
            }
          }
        }
        .ttl {
          font-size: 14px;
          font-weight: 500;
          max-inline-size: max-content;
          background: linear-gradient(#fff, #fff) no-repeat left bottom / 100% 0px;
          transition: 0.3s;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover {
          &::before {
            transform: translateX(5px);
          }
          .ttl {
            background-size: 100% 1px;
          }
        }
      }
    }

    .scroll {
      width: 140px;
      font-size: 11px;
      @include Canta(700);
      letter-spacing: 0.24em;
      color: #fff;
      line-height: 1;
      position: absolute;
      left: 36px;
      bottom: 130px;
      transform-origin: left top;
      transform: rotate(90deg);
      &::before,
      &::after {
        content: "";
        display: block;
        position: absolute;
        width: 80px;
        height: 1px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::before {
        background-color: #c4cbd8;
      }
      &::after {
        background-color: #2abbbc;
        animation: mvScroll 1.5s ease-in-out infinite alternate;
        transform-origin: left top;
      }
    }

    &.loaded {
      &::before {
        animation: loadingFilter 1s 0.5s ease-in-out forwards;
      }
      .catch {
        clip-path: inset(0 0 0 0);
        transition: 0.3s 1.2s ease-out;
      }
      .news_wrap {
        opacity: 1;
        transition: 0.6s 1.2s ease-out;
      }
    }
  }

  .block-business {
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    .container {
      display: grid;
      grid-template-columns: 50% 43%;
      justify-content: space-between;
      align-items: center;
      height: 100vh;
      max-width: 1300px;
    }
    @include pc {
      picture {
        transform: translateY(15%);
      }
    }
    .text_wrap {
      max-width: 420px;
      .block_ttl {
        strong {
          color: #00f2f2;
        }
        small {
          color: #fff;
        }
        strong::before {
          background-image: url(/assets/img/common/icon_ttl_bl.svg);
        }
      }
      p {
        margin-top: 37px;
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        line-height: 2;
        letter-spacing: 0.14em;
      }
      .btn_wrap {
        margin-top: 40px;
      }
    }
  }

  .block-strength {
    padding-block: 194px 140px;
    background-color: #fff;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 75%;
      height: 100%;
      background-color: #f4f6f9;
      left: 0;
      top: 0;
    }
    .top_container {
      z-index: 1;
      @include pc {
        max-width: calc(50% + 530px + 2rem);
        display: grid;
        grid-template-columns: 570px 1fr;
        align-items: center;
        padding-right: 0;
        margin-right: 0;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 353px;
        height: 400px;
        background: url(/assets/img/home/strength_img.svg) no-repeat 0 / contain;
        left: -180px;
        top: -154px;
        z-index: -1;
      }
      picture {
        order: 2;
        flex: 1;
      }
      .text_wrap {
        max-width: 400px;
        order: 1;
        p {
          font-size: 15px;
          font-weight: 700;
          line-height: 2.2;
          margin-top: 20px;
          letter-spacing: 0.14em;
        }
        .btn_wrap {
          margin-top: 35px;
        }
      }
    }
  }

  .block-company {
    background-color: #fff;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 75%;
      height: 300px;
      background-color: #f4f6f9;
      left: 0;
      top: 0;
    }
    .container {
      max-width: calc(1200px + 4rem);
    }
    .img_wrap {
      position: relative;
      overflow: hidden;
      a {
        pointer-events: none;
      }
      picture {
        display: block;
        @include pc {
          height: 410px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }
      }
      .block_ttl {
        position: absolute;
        left: 110px;
        top: 110px;
        strong,
        small {
          color: #fff;
        }
      }

      .btn_wrap {
        position: absolute;
        right: 50px;
        bottom: 35px;
        span {
          pointer-events: auto;
          transition: 0.3s;
          &::before {
            transition: 0.3s;
          }
        }
      }
      a:hover {
        img {
          transform: scale(1.1);
        }
      }
    }
  }

  .block-news {
    padding-block: 110px 80px;
    background-color: #fff;
    position: relative;
    .tag_list {
      li {
        line-height: 1;
        @include pc {
          + li {
            margin-top: 18px;
          }
        }
        a {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.1em;
          padding-bottom: 8px;
          background: linear-gradient(#bec9db, #bec9db) no-repeat left bottom / 100% 0;
          display: block;
          width: 100px;
          padding-left: 25px;
          box-sizing: border-box;
          position: relative;
          &::before,
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $thema-bl;
            bottom: 1px;
          }
          &::before {
            width: 16px;
            height: 16px;
            left: 0;
            top: -1px;
            opacity: 0;
            transition: 0.3s;
          }
          &::after {
            width: 6px;
            height: 6px;
            left: 5px;
            top: 4px;
          }
          &:hover {
            background-size: 100% 1px;
            color: $thema-bl;
            &::before {
              opacity: 0.2;
            }
          }
        }
        &.is-all a {
          background-size: 100% 1px;
          color: $thema-bl;
          &::before {
            opacity: 0.2;
          }
        }
      }
    }
    .news_list {
      max-width: 730px;
      li {
        + li {
          border-top: 1px solid #d6dee8;
        }
      }
      a {
        display: grid;
        grid-template-columns: 140px 1fr;
        column-gap: 50px;
        align-items: center;
        padding-block: 16px;
        box-sizing: border-box;
        position: relative;
        figure {
          border-radius: 3px;
          overflow: hidden;
          aspect-ratio: 1 / 1;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }
        .text_wrap {
          overflow: hidden;
          .info {
            display: flex;
            align-items: center;
            line-height: 1;
            .date {
              font-size: 12px;
              @include Canta(400);
            }
            .cate {
              font-size: 12px;
              font-weight: 500;
              &::before {
                content: "";
                display: inline-block;
                position: relative;
                width: 1px;
                height: 13px;
                background-color: $font-color;
                margin-inline: 7px;
                top: 2px;
              }
            }
          }
          .ttl {
            margin-top: 14px;
            line-height: 1.5;
            overflow: hidden;
            text-overflow: ellipsis;
            @include pc {
              white-space: nowrap;
            }
            span {
              font-size: 18px;
              font-weight: 700;
              background: linear-gradient($thema-bl, $thema-bl) no-repeat left bottom / 100% 0;
              transition: 0.3s;
              letter-spacing: 0.14em;
            }
          }
        }
        &.is-new .text_wrap .ttl::after {
          content: "NEW";
          @include Roboto(500);
          font-size: 14px;
          color: $thema-rd;
          margin-left: 10px;
          @include pc {
            position: relative;
            bottom: 2px;
          }
        }
        &:hover {
          &::before {
            transform: translateX(5px);
          }
          img {
            transform: scale(1.1);
          }
          .text_wrap .ttl span {
            background-size: 100% 1px;
            color: $thema-bl;
          }
        }
      }
    }
    .btn_wrap {
      a {
        &::after {
          content: "\f03a";
          font-family: Font Awesome\6 Free;
          font-weight: 900;
          margin-left: 12px;
          color: $thema-rd;
          position: relative;
          top: 2px;
        }
        span {
          border-bottom: 1px solid $font-color;
          display: inline-block;
          font-size: 13px;
          @include Mulish(600);
          padding: 0 4px 5px;
          transition: 0.3s;
          letter-spacing: 0.14em;
        }
        &:hover {
          span {
            color: $thema-rd;
            border-color: $thema-rd;
          }
        }
      }
    }

    @include pc {
      .container {
        display: grid;
        grid-template-columns: 286px 1fr;
        max-width: calc(1080px + 4rem);
      }
      h2 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }
      .tag_list {
        margin-top: 50px;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
      .news_list {
        grid-column: 2 / 3;
        grid-row: 1 / 4;
      }
      .btn_wrap {
        margin: 0 0 0 auto;
        grid-column: 2 / 3;
        grid-row: 4 / 5;
      }
    }
  }

  @keyframes imgCarousel {
    100% {
      transform: translateX(-5757px);
      @include sp {
        transform: translateX(-3214px);
      }
    }
  }

  .block-recruit {
    padding-block: 110px 90px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    overflow: hidden;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 436px;
      background-color: #2abbbc;
      left: 0;
      top: 0;
      z-index: -1;
    }
    @include pc {
      .container {
        max-width: calc(1080px + 4rem);
      }
      .carousel_wrap {
        margin-inline: calc(50% - 50vw);
      }
    }
    h2 {
      width: 557px;
      margin-bottom: -41px;
      position: relative;
      z-index: 1;
    }
    .carousel_wrap {
      ul {
        display: grid;
        animation: imgCarousel 70s linear infinite;
        width: 11502px;
        column-gap: 12px;
        grid-template-columns: repeat(2, 1fr);
        picture {
          display: block;
        }
        img {
          width: 100%;
        }
      }
    }

    .content_wrap {
      margin-top: -28px;
      h3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        transition: 0.6s ease-out 0.3s;
        clip-path: inset(0 0 0 0);
        span {
          display: block;
          font-size: 30px;
          font-weight: 700;
          color: #fff;
          background-color: #2abbbc;
          padding: 12px 25px;
          line-height: 1;
          letter-spacing: 0.08em;
        }
      }
      > p {
        font-size: 15px;
        font-weight: 700;
        line-height: 2.1;
        letter-spacing: 0.14em;
        margin-top: 38px;
        text-align: center;
      }
      ul {
        margin-top: 66px;
      }
      .btn_wrap {
        margin-top: 36px;
        a {
          margin: auto;
        }
      }
      &.lazyhide h3 {
        clip-path: inset(0 100% 0 0);
      }
    }
  }

  @include sp {
    .mainvisual {
      height: 100svh;
      .catch {
        left: 35px;
        bottom: 30%;
        width: 280px;
      }

      .news_wrap {
        width: 100%;
        height: 85px;
        a {
          row-gap: 12px;
          border-top-left-radius: 5px;
          padding-inline: 25px 60px;
          border-radius: 2px;
          &::before {
            right: 28px;
          }
          .info {
            .date {
              font-size: 12px;
            }
            .cate {
              font-size: 12px;
              font-weight: 500;
              margin-left: 14px;
              position: relative;
              &::before {
                width: 1px;
                height: 13px;
                left: -7px;
              }
            }
          }
          .ttl {
            font-size: 14px;
          }
        }
      }

      .scroll {
        display: none;
      }
    }

    .block-business {
      .container {
        display: block;
        padding-block: 50px 76px;
        height: auto;
      }
      picture {
        display: block;
        margin: 0 -15px -78px;
      }
      .text_wrap {
        p {
          margin-top: 26px;
          font-size: 14px;
          line-height: 1.8;
        }
        .btn_wrap {
          margin-top: 26px;
        }
      }
    }

    .block-strength {
      padding-block: 100px 80px;
      &::before {
        width: 68%;
      }
      .top_container {
        &::before {
          width: 225px;
          height: 254px;
          left: -20px;
          top: -97px;
        }
        picture {
          display: block;
          margin-right: -25px;
        }
        .text_wrap {
          margin-top: 35px;
          p {
            font-size: 14px;
            line-height: 1.8;
            margin-top: 25px;
          }
          .btn_wrap {
            margin-top: 25px;
          }
        }
      }
    }

    .block-company {
      .container {
        padding-inline: 0;
        @media(min-width:720px){
          padding-inline: 50px;
        }
      }
      .img_wrap {
        .block_ttl {
          left: 25px;
          top: 42px;
        }
        .btn_wrap {
          right: 0;
          left: 0;
          bottom: 30px;
          @media(min-width:720px){
            bottom: 35px;
            position: absolute;
            right: 50px;
            left: auto;
          }
        }
        img{
          width: 100%;
        }
      }
    }

    .block-news {
      padding-block: 73px 76px;
      .tag_list {
        margin-top: 44px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px 10px;
      }
      .news_list {
        a {
          grid-template-columns: 78px 1fr;
          column-gap: 20px;
          padding-block: 20px;
          .text_wrap {
            .info {
              .cate {
                &::before {
                  height: 13px;
                  margin-inline: 5px;
                  top: 2px;
                }
              }
            }
            .ttl {
              margin-top: 6px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              text-overflow: ellipsis;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
      .btn_wrap {
        margin-top: 14px;
        text-align: center;
        a {
          margin: auto;
          &::after {
            margin-left: 12px;
          }
          span {
            font-size: 13px;
            padding: 0 4px 5px;
          }
        }
      }
    }

    .block-recruit {
      padding-block: 63px 100px;
      &::before {
        height: 210px;
      }
      h2 {
        width: min(100%, 296px);
        margin: 0 0 -20px 10px;
      }
      .carousel_wrap {
        ul {
          width: 6428px;
          column-gap: 5px;
        }
      }

      .content_wrap {
        margin-top: -18px;
        h3 {
          row-gap: 5px;
          span {
            font-size: 18px;
            padding: 8px 10px;
            letter-spacing: 0;
          }
        }
        > p {
          font-size: 14px;
          line-height: 1.8;
          letter-spacing: 0.1em;
          margin-top: 15px;
        }
        .ipad {
          display: inline-block;
          @media (max-width: 555px) {
            display: none;
          }
        }
        ul {
          margin-top: 30px;
        }
        .btn_wrap {
          margin-top: 36px;
        }
      }
    }
  }
}
