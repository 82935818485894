body {
  color: $font-color;

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-weight: 500;
    color: $font-color;
    letter-spacing: .05em;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .3s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

// a.tel {
//   @include pc {
//     pointer-events: none;
//   }
// }

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  @include sp {
    padding-inline: 25px;
  }
}

.link_btn {
  display: flex;
  align-items: center;
  width: 214px;
  height: 54px;
  border-radius: 27px;
  background-color: $thema-bl;
  padding-left: 25px;
  box-sizing: border-box;
  position: relative;
  font-size: 13px;
  @include Mulish(600);
  color: #fff;
  &:visited, &:hover {
    color: #fff;
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $thema-rd;
    right: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    transition: .3s;
  }
  &:hover {
    background-color: $thema-rd;
    &::before {
      background-color: #fff;
    }
  }
  @include sp {
    margin: auto;
  }
}

.block_ttl {
  strong, small {
    display: block;
    line-height: 1;
    color: $thema-bl;
  }
  strong {
    @include Canta(400);
    font-size: 52px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 34px;
      height: 10px;
      background: url(/assets/img/common/icon_ttl.svg) no-repeat 0 / contain;
      left: -40px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  small {
    font-size: 13px;
    font-weight: 700;
    margin-top: 20px;
    margin-left: 5px;
  }
  @include sp {
    strong {
      font-size: 36px;
      &::before {
        width: 20px;
        height: 6px;
        left: -25px;
      }
    }
    small {
      font-size: 11px;
      margin-top: 15px;
      margin-left: 5px;
    }
  }
}

.sec_ttl {
  strong, small {
    display: block;
    line-height: 1;
  }
  strong {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 34px;
      height: 10px;
      background: url(/assets/img/common/icon_ttl.svg) no-repeat 0 / contain;
      left: -45px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
  small {
    font-size: 10px;
    @include Mulish(700);
    color: $thema-bl;
    margin-top: 15px;
    margin-left: 2px;
  }
  @include sp {
    text-align: center;
    strong {
      font-size: 22px;
      &::before {
        width: 20px;
        height: 6px;
        left: 0;
        right: 0;
        bottom: auto;
        top: -18px;
        margin: auto;
        transform: rotate(90deg);
        margin: auto;
      }
    }
    small {
      font-size: 10px;
      margin-top: 6px;
      margin-left: 0;
    }
  }
}

.content-head-banner {
  margin: 100px 0 0 90px;
  h2 {
    margin-left: 46px;
    small {
      margin-top: 12px;
      color: $font-color;
    }
  }
  picture {
    display: block;
    margin-top: 57px;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
  }

  &.nomargin {
    margin: 0;
  }
  .text_ttl {
    text-align: center;
    margin-block: 72px 80px;
    h2 {
      padding-bottom: 12px;
      margin-left: 0;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 24px;
        height: 2px;
        background-color: $thema-rd;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
      strong, small {
        display: block;
        line-height: 1;
      }
      strong {
        color: $thema-bl;
        font-size: 52px;
        @include Canta(400);
        letter-spacing: .06em;
      }
      small {
        margin-top: 18px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: .2em;
      }
    }
    p {
      margin-top: 54px;
      font-size: 17px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: .28em;
    }
  }

  @include sp {
    margin: 40px 0 0 15px;
    h2 {
      margin-left: 9px;
    }
    picture {
      margin-top: 25px;
      @media(min-width:550px){
        height: 271px;
      }
      img{
        width: 100%;
        @media(min-width:550px){
          object-fit: cover;
          height: 100%;
        }
      }
    }

    &.nomargin {
      margin: 0;
    }
    .text_ttl {
      margin-block: 45px 35px;
      h2 {
        strong {
          font-size: 36px;
        }
        small {
          margin-top: 10px;
          font-size: 11px;
          letter-spacing: .14em;
        }
      }
      p {
        margin-top: 32px;
        font-size: 16px;
        line-height: 1.5;
        letter-spacing: .1em;
      }
    }
  }
}

.bn_link {
  max-width: 800px;
  display: block;
  margin: auto;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 11px;
    background: url(/assets/img/common/arrow_bl.svg) no-repeat 0 / contain;
    right: 0;
    bottom: 5px;
  }
  picture {
    overflow: hidden;
    display: block;
    clip-path: polygon(0 0,100% 0,100% 83%,95.8% 100%,0 100%);
    img {
      transition: .3s;
    }
  }
  .text_wrap {
    position: absolute;
    inset: 0;
    margin: auto;
    display: grid;
    align-items: center;
    grid-template-columns: 180px 1fr;
    column-gap: 20px;
    max-inline-size: max-content;
    dt, dd, p {
      color: #fff;
    }
    dl {
      border-right: 1px solid #fff;
      dt {
        @include Canta(400);
        font-size: 43px;
        line-height: 1;
      }
      dd {
        font-size: 13px;
        font-weight: 700;
        line-height: 1;
        margin-top: 9px;
      }
    }
    p {
      font-size: 15px;
      font-weight: 700;
      line-height: 2.1;
      letter-spacing: .14em;
    }
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  @include sp {
    picture {
      clip-path: polygon(0 0, 100% 0, 100% 82%, 91% 100%, 0 100%);
    }
    .text_wrap {
      grid-template-columns: unset;
      place-content: center;
      dl {
        border-right: none;
        text-align: center;
        dt {
          font-size: 33px;
        }
        dd {
          font-size: 12px;
          margin-top: 5px;
        }
      }
      p {
        font-size: 12px;
        line-height: 1.8;
        margin-top: 12px;
      }
    }
  }
}

.recruit_link {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  max-width: 940px;
  margin-inline: auto;
  a {
    display: grid;
    place-content: center;
    place-items: center;
    height: 200px;
    border: 1px solid #E1E9F6;
    border-radius: 3px;
    background-color: #F8F9FD;
    position: relative;
    figure {
      display: grid;
      place-content: center;
      width: 64px;
      height: 52px;
      svg {
        fill: none;
        stroke: $thema-bl;
        stroke-linecap: round;
        stroke-linejoin: round;
        transition: .3s;
      }
    }
    p {
      margin-top: 15px;
      strong, small {
        display: block;
        line-height: 1;
        text-align: center;
        transition: .3s;
      }
      strong {
        font-size: 20px;
        color: $thema-bl;
      }
      small {
        margin-top: 8px;
        font-size: 12px;
        @include Canta(400);
        color: $thema-rd;
      }
    }
    .arrow {
      display: block;
      position: absolute;
      width: 12px;
      height: 11px;
      right: 13px;
      bottom: 13px;
      fill: none;
      stroke: #ABC2D7;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    &:hover {
      background-color: #627597;
      border-color: #E2E8F4;
      figure svg {
        stroke: #fff;
      }
      p {
        strong, small {
          color: #fff;
        }
      }
    }
  }
  li {
    &:nth-of-type(1) figure svg {
      width: 100%;
    }
    &:nth-of-type(2) figure svg {
      width: 62px;
    }
    &:nth-of-type(3) figure svg {
      width: 55px;
    }
  }

  @include sp {
    display: block;
    @media(min-width:630px){
      display: flex;
      justify-content: space-between;
      height: 200px;
      li{
        width: 300px;
        a{
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
    li {
      + li {
        margin-top: 8px;
      }
      margin-top: 8px;
      &:nth-of-type(2) figure svg {
        width: 56px;
      }
      &:nth-of-type(3) figure svg {
        width: 50px;
      }
    }
    a {
      grid-template-columns: 57px 1fr;
      column-gap: 22px;
      place-items: unset;
      align-items: center;
      height: 90px;
      padding-inline: 25px;
      figure {
        width: 57px;
        height: 46px;
      }
      p {
        margin-top: 0;
        strong, small {
          text-align: left;
          @media(min-width:630px){
            text-align: center;
          }
        }
        strong {
          font-size: 16px;
        }
        small {
          margin-top: 5px;
        }
      }
      .arrow {
        right: 7px;
        bottom: 9px;
      }
    }
  }
}

.pan_wrap {
  margin-block: 120px 27px;
  @include pc {
    .container {
      max-width: 1220px;
    }
  }
  ul {
    display: flex;
    li {
      @include Mulish(600);
      font-size: 10px;
      line-height: 1;
      color: $thema-bl;
      + li {
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          width: 10px;
          height: 1px;
          background-color: #B1BACB;
          margin-inline: 12px;
          bottom: 3px;
        }
      }
      a {
        color: $thema-bl;
        &:hover {
          color: $thema-rd;
        }
      }
      &:first-of-type a {
        color: #C3C4C4;
        &:hover {
          color: $thema-rd;
        }
      }
    }
  }
  
  @include sp {
    margin-top: 70px;
  }
}