
.pagetop {
  z-index: 2;
  left: 0;
  transition: all .2s ease-in-out;
  a {
    @include Canta(400);
    font-size: 17px;
    color: $thema-bl;
    position: relative;
    line-height: 1;
    display: block;
    letter-spacing: .14em;
    &:visited {
      color: $thema-bl;
    }
    .arrow {
      display: block;
      width: 15px;
      height: 10px;
      &::before, &::after {
        content: '';
        display: block;
        position: absolute;
        transition: .3s;
      }
      &::before {
        right: 0;
        bottom: 0;
        width: 14px;
        height: 1px;
        background-color: $thema-bl;
      }
      &::after {
        width: 10px;
        height: 10px;
        border-top: 1px solid $thema-bl;
        border-right: 1px solid $thema-bl;
        right: 2px;
        top: 0;
        transform: rotate(-45deg);
      }
    }
    &:hover {
      color: $thema-rd;
      .arrow {
        &::before {
          background-color: $thema-rd;
        }
        &::after {
          border-color: $thema-rd;
        }
      }
    }
  }

  @include pc {
    position: sticky;
    margin: 0 25px 25px auto;
    bottom: 25px;
    max-inline-size: max-content;
    a {
      padding-right: 28px;
      .arrow {
        position: absolute;
        right: 0;
        top: 5px;
      }
    }
    &.hidden {
      transform: translateY(200px);
      opacity: 0;
    }
  }

  @include sp {
    position: fixed;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    opacity: 0;
    &.is-active {
      opacity: 1;
      transform: translateY(0);
    }
    a {
      font-size: 17px;
      letter-spacing: .14em;
      background-color: #fff;
      padding-left: 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      height: 55px;
      .arrow {
        position: relative;
        top: 1px;
        &::before {
          width: 14px;
        }
        &::after {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}