.is--recruit {
  .rwd002-container {
    .rwd002-detail {
      a, a:hover, a:visited {
        color: $font-color;
      }
    }
  }
  &.is--article-1, &.is--article-2, &.is--article-3 {
    .rwd002-detail {
      padding: 0;
      margin-block: 0;
      &__entry {
        max-width: unset;
      }
    }
  }
  .rwd002-block-freearea {
    margin: 0;
    padding: 0;
    max-width: unset;
    &__inner {
      max-width: none;
    }
  }

  .pan_wrap {
    margin-top: 80px;
    @include sp {
      margin-top: 40px;
    }
  }

  .block-entry {
    background-color: $thema-bl;
    padding-block: 60px 80px;
    h3 {
      text-align: center;
      strong, small {
        display: block;
        line-height: 1;
        color: #fff;
      }
      strong {
        font-size: 44px;
        @include Canta(400);
        letter-spacing: .1em;
      }
      small {
        margin-top: 7px;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: .24em;
      }
    }
    ul {
      display: flex;
      justify-content: center;
      column-gap: 40px;
      margin: 22px auto 0;
      max-width: 880px;
      li {
        width: 266px;
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 15px;
          height: 72px;
          background-color: #fff;
          border-radius: 36px;
          position: relative;
          box-sizing: border-box;
          span {
            color: $thema-bl;
            font-size: 17px;
            font-weight: 700;
            letter-spacing: .14em;
            transition: .3s;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $thema-rd;
            right: 25px;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: .3s;
          }
          svg {
            width: 12px;
            fill: none;
            stroke: $thema-rd;
            stroke-miterlimit: 10;
            stroke-width: 2px;
            transition: .3s;
          }
          &.outlink {
            &::before {
              display: none;
            }
          }
          &:hover {
            background-color: $thema-rd;
            span {
              color: #fff;
            }
            &::before {
              background-color: #fff;
            }
            svg {
              stroke: #fff;
            }
          }
        }
      }
    }

    .tel_wrap {
      padding-top: 20px;
      margin: 35px auto 0;
      text-align: center;
      max-width: 655px;
      border-top: 1px solid #fff;
      p, dt, dd, a {
        color: #fff;
      }

      .tel_ttl {
        font-size: 15px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: .16em;
      }

      dl {
        margin-top: 15px;
      }
      dt {
        font-size: 13px;
        letter-spacing: .14em;
      }
      .num {
        max-inline-size: max-content;
        margin: 10px auto 0;
        line-height: 1;
        position: relative;
        &::before {
          background: url(/assets/img/common/icon_tel_wh.svg) no-repeat 0 / contain;
          content: "";
          display: block;
          height: 22px;
          left: -29px;
          position: absolute;
          top: 5px;
          width: 22px;
        }
        a {
          font-size: 32px;
          @include Mulish(600);
        }
      }
      .note {
        font-size: 10px;
        font-weight: 700;
        margin-top: 6px;
        margin-right: 10px;
      }
    }

    @include sp {
      padding-block: 45px;
      h3 {
        strong {
          font-size: 36px;
          letter-spacing: .08em;
        }
        small {
          margin-top: 7px;
          font-size: 11px;
          letter-spacing: .14em;
        }
      }
      ul {
        display: block;
        margin-top: 17px;
        max-width: 260px;
        li {
          width: 100%;
          + li {
            margin-top: 11px;
          }
          a {
            column-gap: 9px;
            height: 50px;
            background-color: #fff;
            border-radius: 25px;
            span {
              font-size: 15px;
            }
            &::before {
              right: 32px;
            }
            svg {
              width: 10px;
            }
            &.outlink {
              padding-left: 15px;
            }
          }
        }
      }
      .tel_wrap {
        margin: 24px 27px 0;
        padding-top: 28px;
        dl {
          margin-top: 13px;
        }
      }
    }
  }

  .block-bottom {
    margin-top: 66px;
    .container {
      max-width: 1220px;
    }
  }

  &.is--article-1 {
    .rwd002-container {
      margin-top: 63px;
    }

    @keyframes imgCarousel {
      100% {
        transform: translateX(-5760px);
      }
    }

    .rec_ttl {
      padding-top: 110px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 435px;
        background-color: #2ABBBC;
        left: 0;
        top: 0;
        z-index: -1;
      }
      h2 {
        width: 550px;
        margin-left: 160px;
      }
      .carousel_wrap {
        margin-top: -40px;
        overflow: hidden;
        position: relative;
        z-index: -1;
        ul {
          display: grid;
          animation: imgCarousel 70s linear infinite;
          grid-template-columns: repeat(2, 5746px);
          column-gap: 14px;
          li {
            picture {
              display: block;
              margin: 0;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }

    @include pc {
      .block-lead {
        .container {
          max-width: none;
          padding-inline: 40px;
        }
      }
      .block-education, .block-welfare {
        .container {
          max-width: none;
          padding-inline: 50px 40px;
        }
      }
    }

    .block-lead {
      @include pc {
        margin-top: 67px;
        .container {
          display: flex;
          flex-direction: column;
        }
        .anchor_list { order: 1;}
        h3 {
          order: 2;
          margin-top: 96px;
        }
        .gr_wrap { order: 3;}
      }

      .anchor_list {
        display: grid;
        grid-template-columns: repeat(5, 160px);
        column-gap: 10px;
        justify-content: center;
        li {
          a {
            display: grid;
            place-content: center;
            position: relative;
            height: 30px;
            border-radius: 15px;
            border: 2px solid #ECEEEE;
            box-sizing: border-box;
            font-size: 12px;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 6px;
              height: 4px;
              background: url(/assets/img/company/arrow_bottom.svg) no-repeat 0 / contain;
              right: 12px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
            &:hover {
              opacity: .7;
            }
          }
        }
      }
      h3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        span {
          background-color: #2ABBBC;
          color: #fff;
          font-size: 37px;
          font-weight: 700;
          letter-spacing: .14em;
          line-height: 1;
          padding: 14px 60px;
          clip-path: inset(0 100% 0 0);
          + span {
            margin-top: 13px;
          }
        }
      }
      .gr_wrap {
        padding: 72px 40px 70px;
        box-sizing: border-box;
        background-color: #2ABBBC;
        position: relative;
        margin-top: 60px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 70px;
          height: 14px;
          background-color: #fff;
          clip-path: polygon(0 0, 100% 0, 50% 100%);
          left: 0;
          right: 0;
          top: 0;
          margin: auto;
        }
        h4 {
          max-width: 497px;
          margin: auto;
        }
        picture {
          display: block;
          max-width: 960px;
          margin: 25px auto 0;
        }
        .edge {
          &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #fff;
            clip-path: polygon(0 0, 100% 0, 0 100%);
          }
          &:nth-of-type(1){
            &::before {
              left: 0;
              top: 0;
            }
            &::after {
              left: 0;
              bottom: 0;
              transform: rotate(-90deg);
            }
          }
          &:nth-of-type(2){
            &::before {
              right: 0;
              top: 0;
              transform: rotate(90deg);
            }
            &::after {
              right: 0;
              bottom: 0;
              transform: rotate(180deg);
            }
          }
        }
      }
      &.loaded h3 span {
        clip-path: inset(0 0 0 0);
        transition: .5s ease-out .2s;
      }
    }

    .lead_wrap {
      @include pc {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 80px;
        picture {
          display: block;
          width: 36%;
        }
        .left_wrap {
          flex: 1;
        }
      }
      .left_wrap {
        margin-top: 16px;
      }
      .text_wrap {
        max-width: clamp(450px, 52vw, 570px);
        margin-inline: auto 0;
        dl {
          margin-top: 30px;
          margin-left: 20px;
          dt {
            font-size: 30px;
            font-weight: 700;
            color: #2ABBBC;
            line-height: 1.5;
            letter-spacing: .14em;
          }
          dd {
            margin-top: 28px;
            font-size: 15px;
            line-height: 1.9;
            letter-spacing: .12em;
          }
        }
      }
    }

    .info_wrap {
      max-width: 960px;
      margin: 67px auto 0;
      .border_wrap {
        border: 1px solid #D6D6D6;
        box-sizing: border-box;
        padding: 38px 60px;
        display: grid;
        grid-template-columns: 220px 1fr;
        column-gap: 30px;
        align-items: center;
        min-height: 136px;
        + .border_wrap {
          margin-top: 14px;
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          line-height: 1;
          letter-spacing: .14em;
          &::before {
            content: '-';
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 15px;
          li {
            font-size: 15px;
            line-height: 1;
            letter-spacing: .16em;
            &::before {
              content: '';
              display: inline-block;
              position: relative;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: #8D99B1;
              margin-right: 3px;
              bottom: 3px;
            }
          }
        }
      }
    }

    .careerup {
      margin-top: 59px;
      .text_wrap {
        max-width: 904px;
        margin: auto;
      }
      h4 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1;
        letter-spacing: .14em;
        color: $thema-bl;
      }
      .desc {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.9;
        letter-spacing: .12em;
        margin-top: 23px;
      }
      picture {
        display: block;
        max-width: 960px;
        margin: 48px auto 0;
      }
    }

    .block-education {
      padding-block: 130px 100px;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 80px);
        height: 1px;
        background-color: #E9E9E9;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .block-welfare {
      padding-block: 102px 108px;
    }

    .block-guideline {
      background-color: #F4F6F9;
      padding-block: 115px 107px;
      .container {
        max-width: 1080px;
      }
      .recruit_list {
        margin: 50px auto 0;
        max-width: 946px;
        li {
          + li {
            margin-top: 23px;
          }
          &:nth-last-of-type(-n + 3){
            display: none;
          }
        }
        a {
          display: grid;
          grid-template-columns: 338px 1fr;
          align-items: center;
          column-gap: 34px;
          background-color: #fff;
          padding: 10px 80px 10px 10px;
          box-sizing: border-box;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 11px;
            background: url(/assets/img/common/arrow_bl.svg) no-repeat 0 / contain;
            top: 0;
            right: 35px;
            bottom: 0;
            margin: auto;
            transition: .3s;
          }
          figure {
            aspect-ratio: 34 / 21;
            overflow: hidden;
            img {
              transition: .3s;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          dl {
            dt {
              font-size: 20px;
              font-weight: 700;
              line-height: 1.2;
              letter-spacing: .14em;
            }
            dd {
              font-size: 13px;
              line-height: 2;
              letter-spacing: .12em;
              margin-top: 15px;
              span {
                background: linear-gradient($font-color, $font-color) no-repeat left bottom / 100% 0;
                transition: .3s;
              }
            }
          }
          &:hover {
            &::before {
              transform: translateX(5px);
            }
            dl dd span {
              background-size: 100% 1px;
            }
          }
        }
      }
    }

    @include sp {
      .rwd002-container {
        margin-top: -18px;
      }
  
      @keyframes imgCarousel {
        100% {
          transform: translateX(-3240px);
        }
      }

      .rec_ttl {
        padding-top: 64px;
        &::before {
          height: 200px;
        }
        h2 {
          width: 290px;
          margin-inline: auto;
          transform: translateX(-6px);
        }
        .carousel_wrap {
          margin-top: -19px;
          ul {
            grid-template-columns: repeat(2, 3234px);
            column-gap: 6px;
          }
        }
      }

      .block-lead {
        .container {
          padding-inline: 0;
        }
        h3 {
          span {
            font-size: 18px;
            letter-spacing: .08em;
            padding: 8px 24px;
            + span {
              margin-top: 5px;
            }
          }
        }
        .anchor_list {
          grid-template-columns: repeat(2, 1fr);
          gap: 8px;
          margin-inline: 15px;
          margin-top: 33px;
        }
        .gr_wrap {
          padding: 46px 25px 50px;
          margin-top: 40px;
          &::before {
            width: 25px;
            height: 6px;
          }
          picture {
            margin-top: 20px;
            text-align: center;
          }
        }
      }
  
      .lead_wrap {
        .left_wrap {
          margin-top: 40px;
        }
        .text_wrap {
          margin-inline: auto;
          dl {
            margin-top: 18px;
            margin-left: 0;
            dt {
              font-size: 18px;
              line-height: 1.7;
              letter-spacing: .1em;
              text-align: center;
            }
            dd {
              margin-top: 25px;
              line-height: 1.6;
              letter-spacing: .14em;
            }
          }
        }
      }
  
      .info_wrap {
        margin-top: 25px;
        .border_wrap {
          padding: 24px 22px;
          display: block;
          max-width: 450px;
          margin-inline: auto;
          h4 {
            line-height: 1.2;
            text-indent: -11px;
            margin-left: 11px;
            text-align: center;
          }
          ul {
            display: block;
            margin-top: 14px;
            li {
              letter-spacing: .14em;
              line-height: 1.4;
              padding-left: 14px;
              position: relative;
              + li {
                margin-top: 8px;
              }
              &::before {
                margin-right: 0;
                bottom: auto;
                position: absolute;
                left: 0;
                top: 8px;
              }
            }
          }
        }
      }

      .careerup {
        margin-top: 38px;
        h4 {
          font-size: 18px;
          text-align: center;
        }
        .desc {
          margin-top: 15px;
        }
        picture {
          margin-top: 28px;
          text-align: center;
        }
      }
  
      .block-education {
        padding-block: 50px 60px;
        &::before {
          width: 100%;
        }
      }
  
      .block-welfare {
        padding-block: 50px 60px;
      }
  
      .block-guideline {
        padding-block: 90px 60px;
        .recruit_list {
          margin-top: 25px;
          li {
            + li {
              margin-top: 15px;
            }
          }
          a {
            display: block;
            padding: 10px 10px 25px;
            &::before {
              top: auto;
              right: 12px;
              bottom: 12px;
            }
            dl {
              margin: 22px 16px 0;
              dt {
                font-size: 18px;
              }
              dd {
                line-height: 1.8;
                margin-top: 10px;
              }
            }
          }
        }
      }
    }

  }

  &.is--article-2 {
    .block-staff {
      picture {
        display: block;
      }
      .text_wrap {
        box-sizing: border-box;
        .message {
          line-height: 1;
          color: #FE0000;
          @include Mulish(600);
          font-size: 14px;
          letter-spacing: .2em;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 28px;
            height: 18px;
            background: url(/assets/img/common/icon_ttl_ring.svg) no-repeat 0 / contain;
            left: -20px;
            top: -14px;
          }
        }
        h3 {
          font-size: 28px;
          font-weight: 700;
          line-height: 1.7;
          letter-spacing: .08em;
          margin-top: 33px;
        }
        dl {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          min-height: 90px;
          gap: 8px 12px;
          padding: 20px 20px;
          box-sizing: border-box;
          background-color: #fff;
          margin-top: 32px;
          border-radius: 5px;
          dt {
            display: flex;
            align-items: center;
            column-gap: 18px;
            line-height: 1;
            &::before {
              content: 'profile';
              display: grid;
              place-content: center;
              width: 82px;
              height: 24px;
              line-height: 1;
              color: $thema-bl;
              font-size: 11px;
              font-weight: 700;
              letter-spacing: .12em;
              background-color: #F4F6F9;
              border-radius: 12px
            }
            span {
              @include Canta(400);
              font-size: 28px;
              letter-spacing: .08em;
              color: $thema-bl;
            }
            @include pc {
              @include max(1200px){
                column-gap: 10px;
                &::before {
                  width: 78px;
                }
                span {
                  font-size: 24px;
                }
              }
            }
          }
          dd {
            color: $thema-bl;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: .1em;
          }
        }
        .messa_list {
          margin-top: 40px;
          .desc {
            + .desc {
              margin-top: 50px;
            }
          }
          h4 {
            color: $thema-bl;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.3;
            letter-spacing: .14em;
          }
          p {
            margin-top: 11px;
            font-size: 15px;
            font-weight: 700;
            line-height: 1.9;
            letter-spacing: .12em;
          }
        }
        picture {
          margin-top: 35px;
        }
      }
      &:nth-of-type(2n) {
        .text_wrap dl {
          background-color: #F4F6F9;
          dt::before {
            background-color: #fff;
          }
        }
      }
    }

    @include pc {
      .block-staff {
        .container {
          max-width: calc(50% + 530px);
          display: flex;
          align-items: flex-start;
        }
        .main_pic {
          width: 42%;
          position: sticky;
          top: 0;
        }
        .text_wrap {
          width: 58%;
          padding-block: 108px 90px;
          picture {
            display: block;
          }
        }
        &:nth-of-type(2n + 1){
          .container {
            padding-right: 0;
            margin-right: 0;
            flex-direction: row-reverse;
          }
          .text_wrap {
            padding-right: min(6vw, 107px);
            position: relative;
            background-color: #F4F6F9;
            &::before {
              content: '';
              display: block;
              position: absolute;
              width: 80vw;
              height: 100%;
              background-color: #F4F6F9;
              right: 0;
              top: 0;
              z-index: -1;
            }
          }
        }
        &:nth-of-type(2n){
          .container {
            padding-left: 0;
            margin-left: 0;
          }
          .text_wrap {
            padding-left: min(6vw, 107px);
          }
        }
      }
    }

    @include sp {
      .block-staff {
        padding-bottom: 66px;
        .main_pic {
          margin-inline: -25px;
          img{
            width: 100%;
          }
        }

        .text_wrap {
          margin-top: 40px;
          .message {
            margin-left: 15px;
            &::before {
              left: -20px;
              top: -14px;
            }
          }
          h3 {
            font-size: 18px;
            line-height: 1.7;
            letter-spacing: .1em;
            margin-top: 12px;
            text-align: center;
            @include max(380px){
              font-size: 17px;
            }
          }
          dl {
            min-height: unset;
            display: block;
            padding: 19px 20px 20px;
            margin-top: 20px;
            border-radius: 4px;
            dt {
              column-gap: 14px;
              span {
                letter-spacing: .06em;
              }
            }
            dd {
              margin-top: 9px;
            }
          }
          .messa_list {
            margin-top: 30px;
            .desc {
              + .desc {
                margin-top: 25px;
              }
            }
            h4 {
              font-size: 18px;
              text-align: center;
            }
            p {
              margin-top: 13px;
              line-height: 1.7;
            }
          }
          picture {
            margin-top: 28px;
          }
        }
        &:nth-of-type(2n + 1){
          background-color: #F4F6F9;
        }
      } 
    }
  }

  &.is--article-3 {
    .block-data {
      background-color: #F4F6F9;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 66px;
        height: 13px;
        background-color: #fff;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
      }
    }
    .data_list {
      display: grid;
      li {
        background-color: #fff;
        text-align: center;
        padding: 30px 10px 20px;
        box-sizing: border-box;
        &.wide {
          grid-column: span 2;
        }
        h3 {
          font-size: 14px;
          font-weight: 700;
          line-height: 1.2;
          letter-spacing: .14em;
          padding-bottom: 8px;
          position: relative;
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 2px;
            background-color: #869CBB;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .info {
          color: #0057A8;
          font-size: 13px;
          font-weight: 700;
          line-height: 1.2;
          margin-top: 12px;
          strong {
            @include Jost(400);
            font-size: 32px;
            line-height: 1;
          }
        }
        .text {
          font-size: 12px;
          font-weight: 700;
          letter-spacing: .14em;
          line-height: 1.1;
          color: #0057A8;
          margin-top: 4px;
        }
        figure {
          display: grid;
          place-content: center;
          width: 119px;
          height: 61px;
          margin: 10px auto 0;
        }
        &:nth-of-type(2) img {
          width: 51px;
        }
        &:nth-of-type(3) img {
          width: 119px;
        }
        &:nth-of-type(4) img {
          width: 91px;
        }
        &:nth-of-type(5) img {
          width: 67px;
        }
        &:nth-of-type(6) img {
          width: 78px;
        }
        &:nth-of-type(7) img {
          width: 55px;
        }
        &:nth-of-type(8) img {
          width: 69px;
        }
        &:nth-of-type(9) img {
          width: 97px;
        }
        &:nth-of-type(10) img {
          width: 74px;
        }
        &:nth-of-type(11) img {
          width: 107px;
        }
        &:nth-of-type(12) img {
          width: 95px;
        }
      }
    }
    .term {
      font-size: 12px;
      color: #6E84A5;
      letter-spacing: .14em;
      line-height: 1;
      margin-top: 15px;
      text-align: right;
    }
    
    @include pc {
      .block-data {
        margin: 0 40px 96px;
        padding-block: 80px 54px;
        .edge {
          &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            width: 12px;
            height: 12px;
            background-color: #fff;
            clip-path: polygon(0 0, 100% 0, 0 100%);
          }
          &:nth-of-type(1){
            &::before {
              left: 0;
              top: 0;
            }
            &::after {
              left: 0;
              bottom: 0;
              transform: rotate(-90deg);
            }
          }
          &:nth-of-type(2){
            &::before {
              right: 0;
              top: 0;
              transform: rotate(90deg);
            }
            &::after {
              right: 0;
              bottom: 0;
              transform: rotate(180deg);
            }
          }
        }
      }
      .container {
        max-width: 1060px;
        padding-inline: 30px;
      }
      .data_list {
        grid-template-columns: repeat(5, 1fr);
        gap: 12px;
        li {
          min-height: 216px;
          &.large {
            grid-column: 1 / 3;
            grid-row: 1 / 3;
            padding-top: 87px;
            .info {
              font-size: 24px;
              margin-top: 32px;
              strong {
                font-size: 59px;
              }
            }
            figure {
              width: 136px;
              height: 127px;
              margin-top: 19px;
              img {
                width: 136px;
              }
            }
          }
        }
      }
    }

    @include sp {
      .block-data {
        padding-block: 36px 60px;
        &::before {
          width: 27px;
          height: 5px;
        }
      }
      .data_list {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        li {
          padding: 30px 10px 20px;
          h3 {
            font-size: 14px;
            line-height: 1.2;
            letter-spacing: .14em;
            padding-bottom: 6px;
            &::before {
              width: 24px;
              height: 2px;
            }
          }
          .info {
            font-size: 13px;
            line-height: 1.2;
            margin-top: 8px;
            strong {
              font-size: 32px;
            }
          }
          .text {
            margin-top: 5px;
            letter-spacing: 0;
          }
          figure {
            width: 93px;
            height: 50px;
            margin-top: 8px;
          }
          &:nth-of-type(1) {
            height: 360px;
            grid-column: 1 / 3;
            padding-top: 60px;
            .info {
              font-size: 22px;
              margin-top: 32px;
              strong {
                font-size: 48px;
              }
              small {
                display: block;
                margin-top: 0px;
                font-size: 10px;
                letter-spacing: .14em;
              }
            }
            figure {
              width: 116px;
              height: 109px;
              margin-top: 20px;
              img {
                width: 100%;
              }
            }
          }
          &:nth-of-type(2) img {
            width: 41px;
          }
          &:nth-of-type(3) img {
            width: 93px;
          }
          &:nth-of-type(4) img {
            width: 73px;
          }
          &:nth-of-type(5) img {
            width: 67px;
          }
          &:nth-of-type(6) img {
            width: 78px;
          }
          &:nth-of-type(7) img {
            width: 50px;
          }
          &:nth-of-type(8) {
            img {
              width: 62px;
            }
          }
          &:nth-of-type(9) img {
            width: 82px;
          }
          &:nth-of-type(10) {
            img {
              width: 67px;
            }
          }
          &:nth-of-type(11) {
            img {
              width: 88px;
            }
          }
          &:nth-of-type(12) {
            img {
              width: 83px;
            }
          }
        }
      }
      .term {
        font-size: 12px;
        color: #6E84A5;
        letter-spacing: .14em;
        line-height: 1;
        margin-top: 15px;
        text-align: right;
      }
    }
  }
}