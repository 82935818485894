.is--news {
  .is-catlist .rwd002-block-content__inner {
    .cate {
      border-color: $thema-bl;
      color: $thema-bl;
      &.current, &:hover {
        background-color: $thema-bl;
        color: #fff;
        border-color: $thema-bl;
      }
    }
  }
  &.is--detail {
    .rwd002-block {
      display: none;
    }
  }
}